<template>
  <div class="imageContainer mt-2 flex-grow-0">
    <div class="image-wrapper">
      <VIcon
        v-if="!disableRemove"
        class="icon-top-left"
        color="error"
        @click="$emit('remove', id)"
      >
        mdi-close-circle
      </VIcon>
      <VIcon v-if="isSelected" class="icon-top-right" color="primary">
        mdi-check-bold
      </VIcon>
      <img
        :src="src"
        alt="Dresskare Media"
        :class="imageCssClassesExtended"
        v-bind="$attrs"
        @click="$emit('select', id)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DresskareBaseImageSelector",
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    disableRemove: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      required: true,
    },
    imageCssClasses: {
      type: String,
      default: "",
    },
  },
  computed: {
    imageCssClassesExtended() {
      let objectClass = this.imageCssClasses
        .split(" ")
        .reduce((agg, className) => {
          agg[className] = true;
          return agg;
        }, {});
      objectClass.selected = this.isSelected;
      return objectClass;
    },
  },
};
</script>

<style scoped>
.imageContainer {
  position: relative !important;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.icon-top-right {
  position: absolute !important;
  right: -10px;
  top: -10px;
}

.icon-top-left {
  position: absolute !important;
  left: -10px;
  top: -10px;
}
.selected {
  border: 2px solid lightblue;
}
</style>
