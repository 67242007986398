<template>
  <RouterView />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import url("scss/components.scss");

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4457ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #596aff;
}
</style>
