import { oidcSettings } from "@/setup/OIDCSettings";
import { tryRefreshSessionOrRedirectIfNotPublic } from "@/setup/auth-hooks";
import * as Sentry from "@sentry/vue";
import VuexORM from "@vuex-orm/core";
import Vue from "vue";
import Vuex from "vuex";
import { vuexOidcCreateStoreModule } from "vuex-oidc";

import { notifications } from "@/dresskare-library/index";

import Announcement from "@/models/Announcement";
import Brand from "@/models/Brand";
import Category from "@/models/Category";
import Color from "@/models/Color";
import Company from "@/models/Company";
import Customer from "@/models/Customer";
import Deposit from "@/models/Deposit";
import DepositImage from "@/models/DepositImage";
import Invoice from "@/models/Invoice";
import Product from "@/models/Product";
import ProductImage from "@/models/ProductImage";
import ProductSize from "@/models/ProductSize";
import RateSlide from "@/models/RateSlide";
import Subscription from "@/models/Subscription";
import Vendor from "@/models/Vendor";

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(Customer);
database.register(Deposit);
database.register(Vendor);
database.register(Product);
database.register(ProductImage);
database.register(Company);
database.register(RateSlide);
database.register(Invoice);
database.register(Announcement);
database.register(Category);
database.register(DepositImage);
database.register(Subscription);
database.register(Brand);
database.register(ProductSize);
database.register(Color);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    oidc: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      {
        userLoaded: (user) => {
          if (process.env.VUE_APP_ENABLE_SENTRY === "true") {
            Sentry.setUser({
              email: user?.profile?.email,
              id: user?.profile?.sub,
            });
          }
        },
        userUnloaded: () => {
          if (process.env.VUE_APP_ENABLE_SENTRY === "true") {
            Sentry.setUser(null);
          }
          console.log("OIDC user is unloaded");
        },
        accessTokenExpiring: () => console.log("Access token will expire"),
        accessTokenExpired: async () => {
          console.log("Access token did expire");
          await tryRefreshSessionOrRedirectIfNotPublic();
        },
        silentRenewError: () => console.log("OIDC user is unloaded"),
        automaticSilentRenewError: (payload) =>
          console.log("Automatic silent renew failed.", payload.error),
        userSignedOut: () => {
          if (process.env.VUE_APP_ENABLE_SENTRY === "true") {
            Sentry.setUser(null);
          }
          console.log("OIDC user is signed out");
        },
        oidcError: (payload) => {
          console.log(`An error occured at ${payload.context}:`, payload.error);
        },
      }
    ),
    notifications,
  },
  plugins: [VuexORM.install(database)],
});
