import { Model } from "@vuex-orm/core";

import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { create } from "@bufbuild/protobuf";
import {
  AnnouncementController,
  AnnouncementRequestSchema,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/vendor_pb";
const announcementClient = useDresskareGrpcClient(AnnouncementController);

let annnouncementPromiseByUuid = {};

export default class Announcement extends Model {
  static entity = "announcement";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      name: this.string(""),
      title: this.string(""),
      description: this.string(""),
      isActive: this.boolean(true),
      isDefault: this.boolean(true),
      vendor: this.attr(null),
      company: this.attr(null),
    };
  }

  static getAnnouncements = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }

      const response = await announcementClient.list(undefined, {
        headers: metadata,
      });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static create = async (form) => {
    try {
      const response = await announcementClient.create(
        create(AnnouncementRequestSchema, form)
      );

      this.insertOrUpdate({ data: response });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static getAnnouncementByUuid = async (announcementUuid) => {
    const rateSlideFromStore = this.find(announcementUuid);

    if (rateSlideFromStore) {
      return rateSlideFromStore;
    } else {
      if (annnouncementPromiseByUuid[announcementUuid]) {
        return annnouncementPromiseByUuid[announcementUuid];
      }
      try {
        const retrievePromise = announcementClient.retrieve(
          { uuid: announcementUuid },
          {}
        );
        annnouncementPromiseByUuid[announcementUuid] = retrievePromise;
        const response = await retrievePromise;

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        delete annnouncementPromiseByUuid[announcementUuid];
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };

  static updateInDb = async (form) => {
    const response = await announcementClient.update(
      create(AnnouncementRequestSchema, form),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static destroy = async (announcementUuid) => {
    try {
      await announcementClient.destroy({ uuid: announcementUuid }, {});
      this.delete(announcementUuid);
    } catch (error) {
      console.error("error:", error);
    }
  };
}
