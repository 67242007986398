import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { Model } from "@vuex-orm/core";

import { ColorController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_pb";
const colorClient = useDresskareGrpcClient(ColorController);

export default class Color extends Model {
  static entity = "color";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      title: this.string(""),
      code: this.string(""),
      hexCode: this.string(""),
      order: this.number(0),
      vintedId: this.string(""),
      colorText: this.string(""),
    };
  }

  static getColors = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }

      const response = await colorClient.list({}, { headers: metadata });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };
}
