<template>
  <VDialog
    :value="value"
    max-width="80%"
    persistent
    @input="$emit('input', $event)"
  >
    <VCard class="pb-3 pt-5 px-12">
      <VCardText class="px-7 mt-3 text-h4 text-center">
        {{ modalTitle }}
      </VCardText>

      <VCardText>
        <VProgressLinear
          :indeterminate="!firstResponseReceived"
          :value="pourcentOfProgress"
          color="primary"
        ></VProgressLinear>

        <p v-if="!firstResponseReceived">
          {{ waitFirstAnswer }}
        </p>
        <p v-if="firstResponseReceived">
          {{ numberItemsFetched }}/{{ numberOfItemsToFetch }}
          {{ elementFetched }}
        </p>
        <p v-if="isFinished">
          {{ finishedMessage }}
        </p>
      </VCardText>

      <VCardActions class="mt-8">
        <div class="flex-grow-1"></div>
        <!-- <VBtn
          color="primary"
          depressed
          class="mr-5"
          :disabled="generating"
          @click="exportInventory"
        >
          <VIcon left> mdi-export </VIcon>
          {{ $t("export") }}
        </VBtn> -->
        <VBtn dark color="#ff5f85" class="mr-5" @click="$emit('input', false)">
          <VIcon left> mdi-close-circle </VIcon>
          {{ $t("cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

import i18n from "@/setup/i18n";

export default {
  name: "DresskareBaseExportModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    grpcStream: {
      type: Function,
      required: true,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    request: {
      type: Object,
      default: undefined,
    },
    modalTitle: {
      type: String,
      default: i18n.t("streamProgress"),
    },
    waitFirstAnswer: {
      type: String,
      default: i18n.t("waitFirstAnswer"),
    },
    elementFetched: {
      type: String,
      default: i18n.t("elementFetched"),
    },
    finishedMessage: {
      type: String,
      default: i18n.t("finishedMessage"),
    },
    options: {
      type: Object,
      default: () => ({ "!cols": [] }),
    },
    progressAttribute: {
      type: String,
      default: "progress",
    },
    countAttribute: {
      type: String,
      default: "count",
    },
  },
  data() {
    return {
      generating: false,
      numberOfItemsToFetch: 0,
      firstResponseReceived: false,
      isFinished: false,
      numberItemsFetched: 0,
    };
  },
  computed: {
    pourcentOfProgress() {
      if (!this.numberOfItemsToFetch) {
        return 0;
      }
      return (this.numberItemsFetched / this.numberOfItemsToFetch) * 100;
    },
  },
  created() {
    if (this.grpcStream) {
      this.launchGRPCStream();
    }
  },
  methods: {
    async launchGRPCStream() {
      this.generating = true;

      let metadata = {};
      if (this.filters) {
        metadata.filters = JSON.stringify(this.filters);
      }

      for await (const response of this.grpcStream(this.request, {
        headers: metadata,
      })) {
        if (!["PROGRESS", "FINISH"].includes(response.code)) {
          this.$emit("specialMessage", response);
        }
        this.numberOfItemsToFetch = response[this.countAttribute];
        this.firstResponseReceived = true;

        this.numberItemsFetched = response[this.progressAttribute];

        if (this.numberItemsFetched === this.numberOfItemsToFetch) {
          this.isFinished = true;
          this.$emit("finished");
        }
      }
    },
  },
};
</script>


<i18n>
{
  "en": {
    "exportInventory": "Inventory export",
    "export": "Export",
    "cancel": "Cancel"
  },
  "fr": {
    "exportInventory": "Export de l'inventaire",
    "export": "Exporter",
    "cancel": "Annuler"
  }
}
</i18n>
