import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { Model } from "@vuex-orm/core";

import { ProductCategoryController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_pb";
const productCategoryClient = useDresskareGrpcClient(ProductCategoryController);

export default class Category extends Model {
  static entity = "category";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      name: this.string(),
      nameSingular: this.string(),
      identifier: this.string(),
      gender: this.string(),
      family: this.string(),
      familySingular: this.string(),
      order: this.number(),
      vintedPathIds: this.attr([]).nullable(),
      productSizeGroup: this.string("").nullable(),
      packageSize: this.string(),
    };
  }

  static getCategories = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }
      metadata.pagination = JSON.stringify({ page_size: 700 });

      const response = await productCategoryClient.list(undefined, {
        headers: metadata,
      });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static getCategoryByUuid = async (categoryUuid) => {
    const categoryFromStore = this.find(categoryUuid);

    // INFO - AM - 09/10/2022 - When retrieving deposit we get vendor first_name and last_name so the vendor exist in store but not fully
    if (categoryFromStore) {
      return categoryFromStore;
    } else {
      try {
        const response = await productCategoryClient.retrieve(
          { uuid: categoryUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };
}

export const CATEGORY_GENDER = {
  MAN: "MAN",
  WOMAN: "WOMAN",
  BOY_CHILD: "BOY_CHILD",
  GIRL_CHILD: "GIRL_CHILD",
};
