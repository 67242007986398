import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { create } from "@bufbuild/protobuf";
import { Model } from "@vuex-orm/core";

import { listAll } from "@dresskare/dresskare-grpc-api/connect/utils";

import {
  RateSlideController,
  RateSlideRequestSchema,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/vendor_pb";
const rateSlideClient = useDresskareGrpcClient(RateSlideController);

export default class RateSlide extends Model {
  static entity = "rateslide";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      priceStart: this.number(0),
      priceEnd: this.number(0),
      commission: this.number(0),
      vendor: this.attr(null),
      deposit: this.attr(null),
      company: this.attr(null),
      dresskareCommission: this.number(0),
    };
  }

  static getRateSlides = async (filters, uselistAll = false) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }

      let response;
      // INFO - AM - 10/08/2024 - useListAll is not used anymore as rate slides are send with deposit serializer but we keep it for listAll implem test and example
      if (uselistAll) {
        let allRateSlides = await listAll(
          rateSlideClient.list,
          {},
          metadata,
          50
        );
        response = {
          results: allRateSlides,
          count: allRateSlides.length,
        };
      } else {
        response = await rateSlideClient.list(undefined, {
          headers: metadata,
        });
      }

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };

  static create = async (form) => {
    try {
      const response = await rateSlideClient.create(
        create(RateSlideRequestSchema, form),
        {}
      );

      this.insertOrUpdate({ data: response });
      return response;
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };

  static getRateSlideByUuid = async (rateSlideUuid) => {
    const rateSlideFromStore = this.find(rateSlideUuid);

    if (rateSlideFromStore) {
      return rateSlideFromStore;
    } else {
      try {
        const response = await rateSlideClient.retrieve(
          { uuid: rateSlideUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.log("error:", error);
        return null;
      }
    }
  };

  static updateInDb = async (form) => {
    const response = await rateSlideClient.update(
      create(RateSlideRequestSchema, form),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static destroy = async (rateSlideUuid) => {
    try {
      await rateSlideClient.destroy({ uuid: rateSlideUuid }, {});
      this.delete(rateSlideUuid);
    } catch (error) {
      console.log("error:", error);
    }
  };
}
